import React from 'react';
import get from 'lodash/get';
import { connect } from 'react-redux';
import {
  DISCONTINUED_CODES_YR,
  NON_DISCONTINUED_CODE,
  BACK_ORDER_MESSAGE,
  PRE_ORDER_MESSAGE,
  SHIP_FORM_VENDOR_STATUS,
  SHIP_FORM_VENDOR_MESSAGE,
  BACK_ORDER_TEXT,
  SHIPS_FROM_VENDOR,
} from 'pdp/constants';
import xss from 'xss';
import './skuStatus.css';

const BACK_ORDER = 'Back Order';
const IN_STOCK = 'In Stock';
const MERCHANDISE_TYPE_OTHERS = 'OTHERS';

const onlyXLeft = (quantity) => `Only ${quantity} Left`;

const shouldShowOnlyXLeft = (sku, threshold) => sku.stockLevel
  && sku.stockLevel <= threshold
  && !sku.dropShip
  && !sku.backOrder
  && !sku.preOrder;

const updateStockStatusMessageForXLeft = (
  sku,
  preciousJewelryItem,
  isChanel,
  threshold
) => (
  shouldShowOnlyXLeft(sku, threshold)
  && !preciousJewelryItem
  && !isChanel
  && `${sku.stockStatusMessage}, ${onlyXLeft(sku.stockLevel)}`
);

const updateStockStatusMessageForBackOrder = (sku, selectedSkuQuantity) => {
  if (sku.inStock && selectedSkuQuantity > sku.stockLevel) {
    if (sku.discontinuedCode === NON_DISCONTINUED_CODE) return BACK_ORDER;
    if (DISCONTINUED_CODES_YR.includes(sku.discontinuedCode) && sku.poQuantity) {
      return BACK_ORDER;
    }
  }
  return '';
};

const containsDate = (date) => {
  const datePattern = /\b\d{2}\/\d{2}\/\d{4}\b/;
  return datePattern.test(date);
};

const renderStockStatusMessage = ({
  isPreOrder,
  shippingStatus,
  isBackOrder,
  isShipFromVendor,
  messages,
  isVendorOrStock,
}) => {
  const isContainDate = containsDate(shippingStatus);

  if (isPreOrder) {
    return (
      <>
        <div
          className="sku-status-header"
          dangerouslySetInnerHTML={{ __html: xss(PRE_ORDER_MESSAGE) }}
        />
        {isContainDate && <div className="sku-status-date-text">{shippingStatus}</div>}
      </>
    );
  }

  if (isBackOrder) {
    return (
      <>
        {!isVendorOrStock && (
          <div
            className="sku-status-header"
            dangerouslySetInnerHTML={{ __html: xss(BACK_ORDER_MESSAGE) }}
          />
        )}
        <div className="sku-status-date-text">
          {isContainDate ? shippingStatus : BACK_ORDER_TEXT}
        </div>
      </>
    );
  }

  if (isShipFromVendor) {
    return (
      <>
        <div
          className="sku-status-header"
          dangerouslySetInnerHTML={{ __html: xss(SHIP_FORM_VENDOR_MESSAGE) }}
        />
        {isContainDate && <div className="sku-status-date-text">{shippingStatus}</div>}
      </>
    );
  }

  return (
    <div className="sku-status-messages">
      {messages.map((message, index) => (
        <p key={index} className="message">
          {message}
        </p>
      ))}
    </div>
  );
};

const SkuStatusMessages = (props) => {
  const {
    selectedSku = {},
    perishable,
    selectedSkuQuantity,
    onlyXLeftThreshold,
    preciousJewelryItem,
    isChanel,
    merchandiseType = '',
    hcSkuStatusMessage,
  } = props;

  const shippingStatus = selectedSku.shippingStatusMessage;
  const merchandiseTypeValidation = merchandiseType === MERCHANDISE_TYPE_OTHERS
    && selectedSku.dropShip === true
    && selectedSku.sellable === true;

  const stockStatus = merchandiseTypeValidation
    ? IN_STOCK
    : selectedSku.stockStatusMessage;

  const messages = [];

  const isBackOrder = hcSkuStatusMessage
    && selectedSku?.backOrder
    && (selectedSku.expectedShipDays > 0
    || selectedSku.expectedShipDays === 'U')
    && !selectedSku.preOrder;

  const isPreOrder = selectedSku?.preOrder
    && hcSkuStatusMessage
    && selectedSku?.stockStatusMessage !== SHIP_FORM_VENDOR_STATUS;

  const isShipFromVendor = hcSkuStatusMessage
    && selectedSku?.stockStatusMessage === SHIP_FORM_VENDOR_STATUS
    && selectedSku?.dropShip;

  const isVendorOrStock = (stockStatus === SHIPS_FROM_VENDOR && selectedSku?.dropShip)
  || stockStatus === IN_STOCK;

  if (!merchandiseTypeValidation && shippingStatus && !perishable) {
    messages.push(shippingStatus);
  }

  if (stockStatus) {
    const backOrderMessage = updateStockStatusMessageForBackOrder(
      selectedSku,
      selectedSkuQuantity
    );
    const onlyXLeftMessage = updateStockStatusMessageForXLeft(
      selectedSku,
      preciousJewelryItem,
      isChanel,
      onlyXLeftThreshold
    );

    if (backOrderMessage) {
      messages.push(backOrderMessage);
    } else if (onlyXLeftMessage) {
      messages.push(onlyXLeftMessage);
    } else {
      messages.push(stockStatus);
    }
  }

  if (!messages.length) {
    return false;
  }

  return messages.length || isShipFromVendor ? (
    <div
      data-test="pdp-sku-status"
      className={`sku-status-container ${isBackOrder ? 'back-order' : ''}`}
    >
    {renderStockStatusMessage({
      isPreOrder,
      shippingStatus,
      isBackOrder,
      isShipFromVendor,
      messages,
      isVendorOrStock,
    })}
    </div>
  ) : null;
};

const mapStateToProps = (state) => {
  const siloCategory = get(state.navigation, 'breadcrumbs[0].id', '');
  const onlyXLeftThresholds = state.onlyXLeftThresholds;
  const onlyXLeftThreshold = get(
    onlyXLeftThresholds,
    siloCategory,
    onlyXLeftThresholds.defaultThreshold
  );

  return {
    onlyXLeftThreshold,
  };
};

export default connect(mapStateToProps)(SkuStatusMessages);
