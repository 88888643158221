/* eslint import/no-cycle: 0 */
import {
  getCache,
  putCache,
} from 'client-utils/utilities-mcache';
import compact from 'lodash/compact';
import capitalize from 'lodash/capitalize';
import get from 'lodash/get';
import remove from 'lodash/remove';
import isEmpty from 'lodash/isEmpty';
import isArray from 'lodash/isArray';
import log from 'server/utilities/logger';
import { normalizeJSON, normalizeProductJSON } from 'server/utilities/contentful';
import httpWithLogging from 'universal/http-client';
import { buildCookieString } from 'universal/utilities-cookies';
import {
  setPageVisited, setEditorialBreadcrumbs, setEditorialClass, CONTENT_TEST,
} from 'shared/actions/actions-page';
import { resolveEdtiorialBreadcrumbs } from 'client-utils/editorialUtagResolver';
import { getCloudinaryImageSetIfEnabled } from 'client-utils/utilities-cloudinary';
import { getFavoriteList, markProductsAsYMAL, markProductsIsFavorites } from 'cms/actions/utils';
import {
  Channels, CMSParams, MAIN_SHOT_TYPE, CURATED,
} from '../constants';
import { removeDraftsFromLayout, getStaticJsonData, productIdComparator } from '../utilities';
import { NMDesktopFallback } from '../fallback/nm-desktop-fallback';
import { NMMobileFallback } from '../fallback/nm-mobile-fallback';
import { HCDesktopFallback } from '../fallback/hc-desktop-fallback';
import { HCMobileFallback } from '../fallback/hc-mobile-fallback';


export const LOADING_CMS_ENTRIES = 'LOADING_CMS_ENTRIES';
export const RESOLVED_CMS_ENTRIES = 'RESOLVED_CMS_ENTRIES';
export const REJECTED_CMS_ENTRIES = 'REJECTED_CMS_ENTRIES';
export const RESOLVED_CMS_STORIES = 'RESOLVED_CMS_STORIES';
export const LOADING_EDITORIAL_STORIES = 'LOADING_EDITORIAL_STORIES';
export const RESOLVED_EDITORIAL_STORIES = 'RESOLVED_EDITORIAL_STORIES';
export const REJECTED_EDITORIAL_STORIES = 'REJECTED_EDITORIAL_STORIES';
export const LOADING_CMS_GLOBAL = 'LOADING_CMS_GLOBAL';
export const RESOLVED_CMS_GLOBAL = 'RESOLVED_CMS_GLOBAL';
export const REJECTED_CMS_GLOBAL = 'REJECTED_CMS_GLOBAL';
export const SET_STORY_CLASSIFICATION = 'SET_STORY_CLASSIFICATION';
export const OPEN_POPOUT = 'OPEN_POPOUT';
export const CLOSE_POPOUT = 'CLOSE_POPOUT';
export const EDITORIAL_NAVLINK_CLICK = 'EDITORIAL_NAVLINK_CLICK';
export const LOADING_MODAL_CONTENT = 'LOADING_MODAL_CONTENT';
export const RESOLVED_MODAL_CONTENT = 'RESOLVED_MODAL_CONTENT';
export const REJECTED_MODAL_CONTENT = 'REJECTED_MODAL_CONTENT';
export const SET_ACTIVE_ENTRY_ID = 'SET_ACTIVE_ENTRY_ID';
export const SET_RAIL_LINK_HOVERED = 'SET_RAIL_LINK_HOVERED';
export const SET_RAIL_LINK_NOT_HOVERED = 'SET_RAIL_LINK_NOT_HOVERED';
export const SET_RAIL_HOVERED = 'SET_RAIL_HOVERED';
export const SET_RAIL_NOT_HOVERED = 'SET_RAIL_NOT_HOVERED';
export const OPEN_PRODUCT_PANEL = 'OPEN_PRODUCT_PANEL';
export const CLOSE_PRODUCT_PANEL = 'CLOSE_PRODUCT_PANEL';
export const LOADING_PRODUCT_PANEL = 'LOADING_PRODUCT_PANEL';
export const ERROR_PRODUCT_PANEL = 'ERROR_PRODUCT_PANEL';
export const FAVORITE_PRODUCT_PANEL = 'FAVORITE_PRODUCT_PANEL';
export const DRAWER_HANDLER = 'DRAWER_HANDLER';

const BRAND = NMConfig.BRAND_NAME || 'NM';

export function setStoryClassification(classification) {
  return (dispatch) => dispatch({ type: SET_STORY_CLASSIFICATION, payload: classification });
}

export function getEditorialStories(query) {
  return (dispatch, getState) => {
    dispatch({ type: LOADING_EDITORIAL_STORIES });

    const state = getState();
    const { toggles } = state;
    const useCMSLambda = get(toggles, 'CMS_SERVICE', false);
    const addApiKeyToHeader = get(toggles, 'CONTENT_LAMBDA_API_KEY', false);
    const usePreview = state.api.requestContext[CMSParams.CMS_PREVIEW]
      && (state.api.requestContext.isInternalIp
        || isEmpty(state.api.requestContext.TRUE_CLIENT_IP));
    let cmsCallString;
    const headers = {};

    if (useCMSLambda) {
      if (addApiKeyToHeader) {
        headers['x-api-key'] = global.contentMgtV1_SSMKey;
        cmsCallString = `${NMConfig.API_CONTENT_MGT}/v1/content?cPath=${query}`;
      } else {
        cmsCallString = `${NMConfig.API_CONTENT_MGT}/content?cPath=${query}`;
      }
      cmsCallString = usePreview ? cmsCallString.concat('&preview=true') : cmsCallString;
    }

    const requestApi = httpWithLogging(state, 5000);
    return requestApi
      .get(cmsCallString, { headers })
      .then((response) => {
        const responseData = response.data;
        const normalPayload = useCMSLambda ? responseData : normalizeJSON(responseData);
        const pageContent = get(normalPayload, '[0]', {});
        const pageContentFields = get(pageContent, 'fields', {});
        const pageContentSlots = pageContentFields.contentSlots || [];
        const editorialStories = remove(pageContentSlots, { fields: { type: 'Editorial Story' } });
        if (!editorialStories) {
          dispatch({
            type: REJECTED_EDITORIAL_STORIES,
          });
        }
        if (!isEmpty(editorialStories)) {
          dispatch({
            type: RESOLVED_EDITORIAL_STORIES,
            payload: editorialStories,
          });
        }
      })
      .catch(() => dispatch({ type: REJECTED_EDITORIAL_STORIES }));
  };
}

export function openPopOut() {
  return (dispatch) => {
    return dispatch({ type: OPEN_POPOUT });
  };
}

export function closePopOut() {
  return (dispatch) => {
    return dispatch({ type: CLOSE_POPOUT });
  };
}

export function setDynamicRailHovered() {
  return { type: SET_RAIL_HOVERED };
}

export function setDynamicRailNotHovered() {
  return { type: SET_RAIL_NOT_HOVERED };
}

export function setDynamicRailLinkHovered() {
  return { type: SET_RAIL_LINK_HOVERED };
}

export function setDynamicRailLinkNotHovered() {
  return { type: SET_RAIL_LINK_NOT_HOVERED };
}

const getServiceUrl = (
  queryParams,
  isMobileVersionAvailable,
  usePreview,
  isNewContentModel,
  segmentationParams = {},
  usePreviewLambda,
  useApiKey,
  cmsSchedulePreview,
) => {
  const {
    query, channel, isInternational, contentId,
  } = queryParams;
  const {
    segToggle, p13nToggle, cmsTags, p13nTags, profileId, abTestTags,
  } = segmentationParams;

  let apiPath = NMConfig.API_CONTENT_MGT;
  if (isNewContentModel) {
    apiPath = NMConfig.API_CONTENT_MGT_V2;
    if (segToggle && !p13nToggle) {
      apiPath = NMConfig.API_CONTENT_SEG;
    } else if (p13nToggle) {
      apiPath = NMConfig.API_P13N_CONTENT_SEG;
    }
  }

  let url = `${apiPath}/content?cPath=${query}`;
  if (useApiKey && !segToggle && !p13nToggle) {
    if (isNewContentModel) {
      url = `${NMConfig.API_CONTENT_MGT_SVC}/v2/content?cPath=${query}`;
    } else {
      url = `${apiPath}/v1/content?cPath=${query}`;
    }
  }
  if (usePreview && usePreviewLambda && isNewContentModel) {
    url = `${NMConfig.API_CONTENT_PREVIEW}/contentPreview?cPath=${query}`;
    url = cmsSchedulePreview ? url.concat(`&schedule=${cmsSchedulePreview}`) : url;
  }

  url = isNewContentModel ? url.concat(`&brand=${BRAND}`) : url;
  url = isMobileVersionAvailable ? url.concat(`&channel=${channel}`) : url;
  url = isInternational ? url.concat('&isInternational=International') : url;
  if (usePreview && !usePreviewLambda) {
    url = url.concat('&preview=true');
  }
  url = profileId ? url.concat(`&profileId=${profileId}`) : url;
  url = !isEmpty(cmsTags) ? url.concat(`&tags=${encodeURI(cmsTags)}`) : url;
  url = !isEmpty(p13nTags) ? url.concat(`&p13n=${encodeURI(p13nTags)}`) : url;
  url = !isEmpty(abTestTags) ? url.concat(`&abTests=${encodeURI(abTestTags)}`) : url;

  if (usePreview && usePreviewLambda && cmsSchedulePreview && segToggle) {
    url = url.concat(`&schedule=${cmsSchedulePreview}`);
  }

  return contentId ? `${NMConfig.API_ENTRY_CONTENT}?entryId=${contentId}` : url;
};

const getResponseData = (response, isPersonalPromo) => {
  if (isPersonalPromo) {
    return response?.data || [];
  }

  return response.data ? [response.data[0]] : [];
};

const getEntries = (updatedContentUrl, requestApi, headers) => {
  return requestApi(updatedContentUrl, headers.v2Lambdaheaders);
};

const getPreviewEntries = (updatedContentUrl, requestApi, headers) => {
  return requestApi(updatedContentUrl, headers.previewLambdaHeader);
};

export function getCmsEntries(query, isQuizOrEditorialPage = false, isPersonalPromo) {
  return (dispatch, getState) => {
    dispatch({ type: LOADING_CMS_ENTRIES });
    const state = getState();
    const { device, toggles } = state;
    let timeout = get(state, 'apiTimeouts.SLS_CONTENT_API_TIMEOUT', 5000);
    const isMobileVersionAvailable = get(toggles, 'MOBILE_VERSION', false);
    const addApiKeyToHeader = get(toggles, 'CONTENT_LAMBDA_API_KEY', false);
    const isMobilePhone = get(device, 'isMobilePhone', false);
    const usePreviewLambda = get(toggles, 'PREVIEW_CONTENT_API', false);
    const newClassificationsToggle = get(toggles, 'NEW_CLASSIFICATIONS_MODEL', false);
    const staticJson = get(toggles, 'CONTENT_STATIC_JSON', false);
    const cmsSchedulePreview = state.api.requestContext?.[CMSParams.CMS_SCHEDULE];
    const usePreview = state.api.requestContext?.[CMSParams.CMS_PREVIEW]
      && (state.api.requestContext.isInternalIp
        || isEmpty(state.api.requestContext.TRUE_CLIENT_IP));
    const { MOBILE, DESKTOP } = Channels;
    const channel = isMobilePhone ? MOBILE : DESKTOP;
    const v1Lambdaheaders = {};
    const v2Lambdaheaders = {};
    const previewLambdaHeader = {};
    const localeValue = get(state, 'locale.countryCode', 'US');
    const isInternational = localeValue !== 'US';
    if (usePreview) {
      v1Lambdaheaders['Cache-Control'] = 'max-age=0';
      v2Lambdaheaders['Cache-Control'] = 'max-age=0';
      if (usePreviewLambda) {
        timeout = get(state, 'apiTimeouts.PREVIEW_CONTENT_API_TIMEOUT', 10000);
        if (global.contentPreview_SSMKey) {
          previewLambdaHeader['x-api-key'] = global.contentPreview_SSMKey;
        }
      }
    }
    if (addApiKeyToHeader) {
      v1Lambdaheaders['x-api-key'] = global.contentMgtV1_SSMKey;
      v2Lambdaheaders['x-api-key'] = global.contentManagement_SSMKey;
    }
    let content;
    if (staticJson && !usePreview && !isPersonalPromo) {
      const format = 'Formatted';
      content = getStaticJsonData(BRAND, channel, isInternational, query, format);
    } else {
      const queryParams = { channel, query, isInternational };
      const requestApi = (url, headers) => httpWithLogging(state, timeout).get(url, { headers });
      const updatedContentUrl = getServiceUrl(
        queryParams,
        isMobileVersionAvailable,
        usePreview,
        true,
        {},
        usePreviewLambda,
        addApiKeyToHeader,
        cmsSchedulePreview,
      );
      content = usePreview && usePreviewLambda
        ? getPreviewEntries(
          updatedContentUrl,
          requestApi,
          { previewLambdaHeader }
        ) : getEntries(
          updatedContentUrl,
          requestApi,
          { v2Lambdaheaders }
        );
    }
    return content
      .then((response) => {
        const normalPayload = staticJson && !usePreview && !isPersonalPromo
          ? response : getResponseData(response, isPersonalPromo);
        const pageContent = get(normalPayload, '[0]', {});
        const pageContentFields = get(pageContent, 'fields', {});
        const pageContentSlots = pageContentFields.contentSlots || [];
        const editorialStories = remove(pageContentSlots, { fields: { type: 'Editorial Story' } });
        const storySummaries = newClassificationsToggle ? get(pageContentFields, 'l1Layouts[0].fields.editorialSummaries', []) : get(pageContentFields, 'l1Layouts[0].fields.frames', []);

        dispatch({
          type: RESOLVED_CMS_ENTRIES,
          payload: isPersonalPromo ? normalPayload : [pageContent],
        });

        if (!isEmpty(editorialStories) || !isEmpty(storySummaries)) {
          const payload = editorialStories.length ? editorialStories : storySummaries;

          dispatch({
            type: RESOLVED_CMS_STORIES,
            payload,
          });
        }

        return ({ normalPayload, query });
      })
      .then((response) => {
        const queryString = response.query.indexOf('/') === 0 ? response.query.substr(1, response.query.length - 1) : response.query;
        const arrPath = queryString.split('/');
        if (isQuizOrEditorialPage) {
          const pathToAnalyticsPageName = (path) => path.split('-').map(capitalize).join(' ');
          if (query.startsWith('/quiz/')) {
            const queryWithoutTrailingSlash = query.replace(/\/+$/, '');
            const quizNameInPath = queryWithoutTrailingSlash.substr(queryWithoutTrailingSlash.lastIndexOf('/') + 1);
            const quizName = pathToAnalyticsPageName(quizNameInPath);
            dispatch(setPageVisited({
              event_name: 'pageLoad',
              page_name: `Quiz:${quizName}`,
              page_definition_id: 'quiz',
              page_type: 'editorial',
              page_template: 'quiz',
            }));
          } else {
            const pageDefinitionId = arrPath.length === 1 ? 'silo' : 'story';
            const pageName = ['Magazine', ...arrPath.slice(1)].map(pathToAnalyticsPageName).join(':');
            const pageType = arrPath[0];
            const isEditorial = query.startsWith('/editorial');
            if (query.includes('sms-text-email-sign-up') && isEditorial) {
              dispatch(setPageVisited({
                page_definition_id: 'textSignUp',
                page_name: 'Text Sign Up',
                page_template: 'user subscription',
                page_type: 'footer page',
              }));
            } else {
              dispatch(setPageVisited({
                event_name: 'pageLoad',
                page_name: pageName,
                page_definition_id: isEditorial ? pageDefinitionId : pageType,
                page_type: pageType,
                page_template: isEditorial ? pageDefinitionId : pageType,
              }));
            }
          }

          const editorialclass = get(response.normalPayload, '[0].fields.metaData.fields.classifications', []);
          dispatch(setEditorialClass({ editorialclass }));
        }

        return (arrPath);
      })
      .then((response) => {
        const breadcrumbs = resolveEdtiorialBreadcrumbs(response);
        if (isQuizOrEditorialPage) {
          dispatch(setEditorialBreadcrumbs({ breadcrumbs }));
        }
      })
      .catch((err) => {
        log.error(`There was an error for getCmsEntries: ${err}`);
        dispatch({ type: REJECTED_CMS_ENTRIES });
      });
  };
}

const getFallBackContent = (channel) => {
  let fallback;
  if (BRAND === 'NM') {
    if (channel.includes('Mobile')) {
      fallback = NMMobileFallback;
    } else {
      fallback = NMDesktopFallback;
    }
  }

  if (BRAND === 'HC') {
    if (channel.includes('Mobile')) {
      fallback = HCMobileFallback;
    } else {
      fallback = HCDesktopFallback;
    }
  }

  return fallback;
};

export function getCmsHomeEntries(query) {
  return async (dispatch, getState) => {
    dispatch({ type: LOADING_CMS_ENTRIES });
    try {
      const state = getState();
      const {
        device,
        toggles,
      } = state;
      let timeout = get(state, 'apiTimeouts.SLS_CONTENT_API_TIMEOUT', 5000);
      const isMobileVersionAvailable = get(toggles, 'MOBILE_VERSION', false);
      const addApiKeyToHeader = get(toggles, 'CONTENT_LAMBDA_API_KEY', false);
      const isMobilePhone = get(device, 'isMobilePhone', false);
      const usePreviewLambda = get(toggles, 'PREVIEW_CONTENT_API', false);
      const cmsSchedulePreview = state.api.requestContext?.[CMSParams.CMS_SCHEDULE];
      const hpSegmentation = get(toggles, 'CMS_HP_SEGMENT', false);
      const p13nSegmentation = get(toggles, 'P13N_SEGMENTATION', false);
      const pzpIdentity = get(toggles, 'PZP_IDENTITY', false);
      const usePreview = state.api.requestContext[CMSParams.CMS_PREVIEW]
        && (state.api.requestContext.isInternalIp
          || isEmpty(state.api.requestContext.TRUE_CLIENT_IP));
      const { MOBILE, DESKTOP } = Channels;
      const localeValue = get(state, 'locale.countryCode', 'US');
      const isInternational = localeValue !== 'US';
      let channel = isMobilePhone ? `${MOBILE},${DESKTOP}` : DESKTOP;
      let headers = usePreview ? { 'Cache-Control': 'max-age=0' } : {};

      const fallback = getFallBackContent(channel);

      if (p13nSegmentation && isMobilePhone) {
        channel = MOBILE;
      }

      if (hpSegmentation && global.contentSegmentation_SSMKey) {
        headers['x-api-key'] = global.contentSegmentation_SSMKey;
        if (addApiKeyToHeader) {
          headers['use-api-key'] = 'true';
        }
      } else if (p13nSegmentation && global.p13nFilter_SSMKey) {
        headers['x-api-key'] = global.p13nFilter_SSMKey;
        if (addApiKeyToHeader) {
          headers['use-api-key'] = 'true';
        }
      } else if (addApiKeyToHeader) {
        headers['x-api-key'] = global.contentManagement_SSMKey;
      }

      if (usePreviewLambda && usePreview) {
        if (global.contentPreview_SSMKey) {
          headers = { 'x-api-key': global.contentPreview_SSMKey };
        }
        timeout = get(state, 'apiTimeouts.PREVIEW_CONTENT_API_TIMEOUT', 10000);
        if (hpSegmentation) {
          headers['use-preview-lambda'] = 'true';
        }
      }
      const queryParams = { channel, query, isInternational };
      const requestApi = (url) => httpWithLogging(state, timeout).get(url, { headers });

      let profileId;
      if (pzpIdentity) {
        profileId = state.user?.resolved_uca_id;
      } else {
        profileId = state.ucaId?.universalCustomerId;
      }

      let cmsTags = get(state, 'api.requestContext.CMS_Tags');
      const p13nTags = get(state, 'api.requestContext.P13N_Tags');

      if (get(toggles, 'ABTEST_CONTENT', false)) {
        let abTestVariation = get(state, `abTestsOpt.${CONTENT_TEST}.variation`, null);
        const abTestContentConfig = get(state, 'abTestContent', []);

        if (abTestVariation && abTestContentConfig.length > 1) {
          const abTestName = abTestContentConfig
            .find((testObj) => testObj[CONTENT_TEST])?.[CONTENT_TEST];
          abTestVariation = abTestContentConfig
            .find((testObj) => testObj[abTestVariation])?.[abTestVariation];

          if (abTestVariation && abTestName) {
            cmsTags = isEmpty(cmsTags) ? `${abTestName}:${abTestVariation}` : cmsTags.concat(`,${abTestName}:${abTestVariation}`);
          }
        }
      }

      const filteredABTests = [];
      const currentABTests = get(state, 'abTestsOpt');

      if (currentABTests) {
        Object.keys(currentABTests).forEach((test) => {
          const { variation, analytics } = currentABTests[test];
          if (variation && variation !== 'a' && analytics) {
            filteredABTests.push(analytics);
          }
        });
      }

      const segmentationParams = hpSegmentation || p13nSegmentation
        ? {
          segToggle: !!hpSegmentation,
          p13nToggle: !!p13nSegmentation,
          cmsTags,
          p13nTags,
          profileId,
          abTestTags: filteredABTests,
        } : {};

      const updatedContentUrl = getServiceUrl(
        queryParams,
        isMobileVersionAvailable,
        usePreview,
        true,
        segmentationParams,
        usePreviewLambda,
        addApiKeyToHeader,
        cmsSchedulePreview,
      );
      let cmsResponse;
      let hpContent;

      try {
        const { data } = await requestApi(updatedContentUrl);
        cmsResponse = data;
      } catch (err) {
        log.error(`There was an error calling api ${updatedContentUrl}: ${err}`);
      }

      if (cmsResponse?.length && !isEmpty(cmsResponse) && !cmsResponse.message) {
        hpContent = cmsResponse;
      } else {
        hpContent = fallback;
      }

      dispatch({
        type: RESOLVED_CMS_ENTRIES,
        payload: isMobileVersionAvailable ? [hpContent[0]] : hpContent,
      });
      return ({ hpContent, query });
    } catch (err) {
      log.error(`There was an error rendering home page content: ${err}`);
      dispatch({ type: REJECTED_CMS_ENTRIES });
      return err;
    }
  };
}

export function getEditorialStoriesV2(query) {
  return (dispatch, getState) => {
    dispatch({ type: LOADING_EDITORIAL_STORIES });
    const state = getState();
    const { device, toggles } = state;
    let timeout = get(state, 'apiTimeouts.SLS_CONTENT_API_TIMEOUT', 5000);
    const isMobileVersionAvailable = get(toggles, 'MOBILE_VERSION', false);
    const addApiKeyToHeader = get(toggles, 'CONTENT_LAMBDA_API_KEY', false);
    const isMobilePhone = get(device, 'isMobilePhone', false);
    const usePreviewLambda = get(toggles, 'PREVIEW_CONTENT_API', false);
    const newClassificationsToggle = get(toggles, 'NEW_CLASSIFICATIONS_MODEL', false);
    const cmsSchedulePreview = state.api.requestContext?.[CMSParams.CMS_SCHEDULE];
    const usePreview = state.api.requestContext[CMSParams.CMS_PREVIEW]
      && (state.api.requestContext.isInternalIp
        || isEmpty(state.api.requestContext.TRUE_CLIENT_IP));
    const { MOBILE, DESKTOP } = Channels;
    const channel = isMobilePhone ? `${MOBILE},${DESKTOP}` : DESKTOP;
    const localeValue = get(state, 'locale.countryCode', 'US');
    const isInternational = localeValue !== 'US';
    let headers = usePreview ? { 'Cache-Control': 'max-age=0' } : {};
    if (addApiKeyToHeader) {
      headers['x-api-key'] = global.contentManagement_SSMKey;
    }
    if (usePreviewLambda && usePreview) {
      if (global.contentPreview_SSMKey) {
        headers = { 'x-api-key': global.contentPreview_SSMKey };
      }
      timeout = get(state, 'apiTimeouts.PREVIEW_CONTENT_API_TIMEOUT', 10000);
    }
    const queryParams = { channel, query, isInternational };
    const requestApi = (url) => httpWithLogging(state, timeout).get(url, { headers });
    const updatedContentUrl = getServiceUrl(
      queryParams,
      isMobileVersionAvailable,
      usePreview,
      true,
      {},
      usePreviewLambda,
      addApiKeyToHeader,
      cmsSchedulePreview,
    );
    const content = requestApi(updatedContentUrl);
    return content
      .then((response) => {
        const normalPayload = response?.data?.[0] || {};
        const payload = newClassificationsToggle ? get(normalPayload, 'fields.l1Layouts[0].fields.editorialSummaries', []) : get(normalPayload, 'fields.l1Layouts[0].fields.frames', []);

        dispatch({
          type: RESOLVED_EDITORIAL_STORIES,
          payload,
        });
      })
      .catch(() => dispatch({ type: REJECTED_EDITORIAL_STORIES }));
  };
}

export function getCmsGlobal(query) {
  return (dispatch, getState) => {
    dispatch({ type: LOADING_CMS_GLOBAL });
    const state = getState();
    const { device, toggles } = state;
    let timeout = get(state, 'apiTimeouts.SLS_CONTENT_API_TIMEOUT', 5000);
    const isMobileVersionAvailable = get(toggles, 'MOBILE_VERSION', false);
    const addApiKeyToHeader = get(toggles, 'CONTENT_LAMBDA_API_KEY', false);
    const isMobilePhone = get(device, 'isMobilePhone', false);
    const staticJson = get(toggles, 'CONTENT_STATIC_JSON', false);
    const usePreviewLambda = get(toggles, 'PREVIEW_CONTENT_API', false);
    const cmsSchedulePreview = state.api.requestContext?.[CMSParams.CMS_SCHEDULE];
    const usePreview = state.api.requestContext[CMSParams.CMS_PREVIEW]
      && (state.api.requestContext.isInternalIp
        || isEmpty(state.api.requestContext.TRUE_CLIENT_IP));
    const { MOBILE, DESKTOP } = Channels;
    const channel = isMobilePhone ? `${MOBILE},${DESKTOP}` : DESKTOP;
    const localeValue = get(state, 'locale.countryCode', 'US');
    const isInternational = localeValue !== 'US';
    let headers = usePreview ? { 'Cache-Control': 'max-age=0' } : {};
    if (addApiKeyToHeader) {
      headers['x-api-key'] = global.contentManagement_SSMKey;
    }
    if (usePreviewLambda && usePreview) {
      headers = { 'x-api-key': global.contentPreview_SSMKey };
      timeout = get(state, 'apiTimeouts.PREVIEW_CONTENT_API_TIMEOUT', 10000);
    }
    let content;
    if (staticJson && !usePreview) {
      const format = 'Formatted';
      content = getStaticJsonData(BRAND, channel, isInternational, query, format);
    } else {
      const queryParams = { channel, query, isInternational };
      const requestApi = (url) => httpWithLogging(state, timeout).get(url, { headers });
      const updatedContentUrl = getServiceUrl(
        queryParams,
        isMobileVersionAvailable,
        usePreview,
        true,
        {},
        usePreviewLambda,
        addApiKeyToHeader,
        cmsSchedulePreview,
      );
      content = requestApi(updatedContentUrl);
    }
    return content
      .then((response) => {
        let normalPayload;
        if (staticJson && !usePreview && response.length === 1) {
          normalPayload = response;
        } else if (response.data) {
          normalPayload = removeDraftsFromLayout([response.data[0]]);
        } else normalPayload = [];
        const pageContent = get(normalPayload, '[0]', {});
        dispatch({
          type: RESOLVED_CMS_GLOBAL,
          payload: isMobileVersionAvailable ? [pageContent] : normalPayload,
        });
        return ({ normalPayload, query });
      })
      .catch(() => dispatch({ type: REJECTED_CMS_GLOBAL }));
  };
}

export function getProductCarouselFromId(contentId) {
  return (dispatch, getState) => {
    dispatch({ type: `LOADING_CMS_PRODUCT_CAROUSEL_${contentId}` });

    const state = getState();
    const { toggles } = state;
    const timeout = get(state, 'apiTimeouts.SLS_CONTENT_API_TIMEOUT', 5000);
    const useCMSLambda = get(toggles, 'CMS_SERVICE', false);
    const addApiKeyToHeader = get(toggles, 'CONTENT_LAMBDA_API_KEY', false);
    const useCache = get(toggles, 'CF_CACHE_PRDS', false);
    const usePreview = state.api.requestContext[CMSParams.CMS_PREVIEW]
      && (state.api.requestContext.isInternalIp
        || isEmpty(state.api.requestContext.TRUE_CLIENT_IP));
    const headers = {};
    let cmsCallString;

    if (useCMSLambda) {
      if (addApiKeyToHeader) {
        headers['x-api-key'] = global.contentMgtV1_SSMKey;
        cmsCallString = `${NMConfig.API_CONTENT_MGT}/v1/productCarousel?contentId=${contentId}`;
      } else {
        cmsCallString = `${NMConfig.API_CONTENT_MGT}/productCarousel?contentId=${contentId}`;
      }
      cmsCallString = usePreview ? cmsCallString.concat('&preview=true') : cmsCallString;
    }
    const requestApi = httpWithLogging(state, timeout);
    return requestApi
      .get(cmsCallString, { headers })
      .then((response) => {
        const responseData = response.data;
        if (responseData.sys.contentType.sys.id === 'productCarouselContentAsset') {
          dispatch({
            type: `RESOLVED_CMS_PRODUCT_CAROUSEL_${contentId}`,
            payload: responseData,
            contentId,
          });
          dispatch(
            getCmsProducts(responseData?.fields?.ids, responseData?.sys?.id, useCache)
          );
        }
      })
      .catch(() => dispatch({ type: `REJECTED_CMS_PRODUCT_CAROUSEL_${contentId}` }));
  };
}

export function getPersonalizedProducts(contentId, title = '', fallbackCategoryId = '', useCache = false) {
  return (dispatch, getState) => {
    dispatch({
      type: `LOADING_PERSONALIZED_PRODUCTS_${contentId}`,
      contentId,
    });
    const state = getState();
    const { toggles } = state;
    const abTests = get(state, 'abTestsOpt', []);
    const path = get(state, 'page.location.pathname', '');
    const pzpIdentity = get(toggles, 'PZP_IDENTITY', false);
    const carouselRender = get(toggles, 'PRODUCT_CAROUSEL_CLIENT_SIDE', false);
    let profileId;
    if (pzpIdentity) {
      profileId = state.user?.resolved_uca_id;
    } else {
      profileId = state.ucaId?.universalCustomerId;
    }
    const filteredABTests = [];
    if (abTests) {
      Object.keys(abTests).forEach((test) => {
        const { variation, analytics } = abTests[test];
        if (variation && analytics) {
          filteredABTests.push(analytics);
        }
      });
    }
    const abTestString = filteredABTests.join(',');
    const timeout = IS_CLIENT ? 500 : 250;
    const requestApi = httpWithLogging(state, timeout);
    const headers = {
      brand: BRAND,
      type: 'products',
      group: abTestString,
      slug: path === '/' ? '/womens' : path,
      component: title,
      channel: 'ONLINE',
    };
    headers['x-feature-toggles'] = `{"PMP_PLP_SRP": ${get(state, 'toggles.PMP_PLP_SRP', false)}}`;
    let apiString = NMConfig.API_PERSONALIZED_PRODUCTS;
    apiString = profileId ? apiString.concat(`${profileId}`) : apiString;

    return requestApi.get(apiString, { headers })
      .then((response) => {
        const personalizedProducts = response?.data?.products;
        const productIds = personalizedProducts.map(({ productid }) => productid);
        dispatch({
          type: `RESOLVED_PERSONALIZED_PRODUCTS_${contentId}`,
          payload: productIds,
          contentId,
        });
        dispatch(getCmsProducts(productIds, contentId, useCache));
      })
      .catch((err) => {
        if (carouselRender) {
          dispatch(getCategoryProducts(fallbackCategoryId));
        }
        log.error(`There was an error rendering personalized product carousel: ${err}`);
        dispatch({
          type: `REJECTED_PERSONALIZED_PRODUCTS_${contentId}`,
          contentId,
        });
      });
  };
}

export function getCmsProducts(productIds, contentId, useCache = false) {
  return (dispatch, getState) => {
    dispatch({
      type: `LOADING_CMS_PRODUCTS_${contentId}`,
      contentId,
    });

    const state = getState();
    const { user, session } = state;
    const requestApi = httpWithLogging(state, 4000);
    const headers = {
      Cookie: buildCookieString({
        JSESSIONID: get(session, 'JSESSIONID', ''),
        ucid: get(user, 'ucid', ''),
        rid: get(user, 'rid', 'US'),
        DYN_USER_ID: get(session, 'DYN_USER_ID', ''),
        W2A: get(session, 'W2A', ''),
      }),
      channel: 'ONLINE',
    };
    headers['x-feature-toggles'] = `{"PMP_PLP_SRP": ${get(state, 'toggles.PMP_PLP_SRP', false)}}`;
    const joinProductIds = (productIds) => {
      return productIds.join(',');
    };

    let prodCallString = `?productIds=${joinProductIds(productIds)}`;
    if (useCache) {
      prodCallString = `${NMConfig.API_PRODUCT_CF}${prodCallString}`;
    } else {
      prodCallString = `${NMConfig.API_PRODUCT}${prodCallString}`;
    }
    console.log('prodCallString', prodCallString, NMConfig.API_PRODUCT);
    const internationalToggle = get(state, 'toggles.INTERNATIONAL', false);
    if (internationalToggle) {
      const currencyCode = get(state, 'locale.currencyCode', 'USD');
      if (currencyCode !== 'USD') {
        const currencyQuery = `&currency=${currencyCode}`;
        prodCallString = `${prodCallString}${currencyQuery}`;
      }
    }

    if (prodCallString.indexOf('?') > -1) {
      const mainShotType = `&mainShotType=${MAIN_SHOT_TYPE}`;
      const calledBy = `&calledBy=${CURATED}`;
      prodCallString = `${prodCallString}${mainShotType}${calledBy}`;
    }
    console.log('products api request', prodCallString, headers);
    return requestApi.get(prodCallString, { headers })
      .then((res) => {
        /* eslint-disable-next-line max-len */
        const sortedProducts = productIds.map((id) => res.data.products.find(((product) => product.id === id)));
        const rawChildIds = sortedProducts.map((product) => {
          if (product?.isGroup === true) {
            return product.childProductIds[0];
          }
          return null;
        });
        const childProductIds = rawChildIds ? compact(rawChildIds) : [];
        if (!isEmpty(childProductIds)) {
          dispatch(getChildProducts(childProductIds, contentId, useCache));
        }
        console.log('products api response', sortedProducts);
        dispatch({
          type: `RESOLVED_CMS_PRODUCTS_${contentId}`,
          payload: normalizeProductJSON(sortedProducts),
          contentId,
        });
      })
      .catch(() => {
        dispatch({
          type: `REJECTED_CMS_PRODUCTS_${contentId}`,
          contentId,
        });
      });
  };
}

export function getChildProducts(childProductIds, contentId, useCache = false) {
  return (dispatch, getState) => {
    dispatch({
      type: `LOADING_CHILD_PRODUCTS_${contentId}`,
      contentId,
    });
    if (!childProductIds || !childProductIds.length) {
      dispatch({
        type: `REJECTED_CHILD_PRODUCTS_${contentId}`,
        contentId,
      });
    }
    const state = getState();
    const { user, session } = state;
    const requestApi = httpWithLogging(state, 6000);
    const headers = {
      Cookie: buildCookieString({
        JSESSIONID: get(session, 'JSESSIONID', ''),
        ucid: get(user, 'ucid', ''),
        rid: get(user, 'rid', 'US'),
        DYN_USER_ID: get(session, 'DYN_USER_ID', ''),
        W2A: get(session, 'W2A', ''),
      }),
    };

    const joinProductIds = (childProductIds) => {
      return childProductIds.join(',');
    };
    let prodCallString = `?productIds=${joinProductIds(childProductIds)}`;
    if (useCache) {
      prodCallString = `${NMConfig.API_PRODUCT_CF}${prodCallString}`;
    } else {
      prodCallString = `${NMConfig.API_PRODUCT}${prodCallString}`;
    }
    const internationalToggle = get(state, 'toggles.INTERNATIONAL', false);
    if (internationalToggle) {
      const currencyCode = get(state, 'locale.currencyCode', 'USD');
      if (currencyCode !== 'USD') {
        const currencyQuery = `&currency=${currencyCode}`;
        prodCallString = `${prodCallString}${currencyQuery}`;
      }
    }
    if (prodCallString.indexOf('?') > -1) {
      const mainShotType = `&mainShotType=${MAIN_SHOT_TYPE}`;
      const calledBy = `&calledBy=${CURATED}`;
      prodCallString = `${prodCallString}${mainShotType}${calledBy}`;
    }
    return requestApi.get(prodCallString, { headers })
      .then((res) => {
        dispatch({
          type: `RESOLVED_CHILD_PRODUCTS_${contentId}`,
          payload: normalizeProductJSON(res.data.products),
          contentId,
        });
      })
      .catch(() => {
        dispatch({
          type: `REJECTED_CHILD_PRODUCTS_${contentId}`,
          contentId,
        });
      });
  };
}

function trimSpaces(str) {
  return str.trim().replace(/\s\s+/g, ' ');
}

function stripHtml(html) {
  return html.replace(/<[^>]+>/g, '');
}

function getTitle(product) {
  const isGroup = product.isGroup;
  const cmosItemNum = get(product, 'metadata.cmosItem', null);
  const designerName = unescape(get(product, 'designer.name', ' '));
  let productName = stripHtml(unescape(product.name));
  if (isGroup) {
    const childProducts = get(product, 'childProducts', []);
    productName = stripHtml(unescape(childProducts.map((product) => { return product.name; }).join(' ')));
  }
  return trimSpaces(`${cmosItemNum} ${designerName} ${productName}`);
}

export function getCategoryProducts(catID = '', noOfProducts = 16) {
  console.log('catID: ', catID);
  return (dispatch, getState) => {
    if (!catID) {
      dispatch({
        type: 'REJECTED_CMS_CURRATED_PRODUCTS_NO_CATEGORY_ID',
        payload: [],
      });
      return Promise.resolve([]);
    }

    dispatch({
      type: `LOADING_CMS_CURRATED_PRODUCTS_${catID}`,
      catID,
    });
    const state = getState();
    const { user, session } = state;
    const requestApi = httpWithLogging(state, 4000);
    const headers = {
      Cookie: buildCookieString({
        JSESSIONID: get(session, 'JSESSIONID', ''),
        ucid: get(user, 'ucid', ''),
        rid: get(user, 'rid', 'US'),
        DYN_USER_ID: get(session, 'DYN_USER_ID', ''),
        W2A: get(session, 'W2A', ''),
      }),
      channel: 'ONLINE',
      'x-feature-toggles': `{"PMP_PLP_SRP": ${get(state, 'toggles.PMP_PLP_SRP', false)}}`,
    };
    const apiConfig = NMConfig.API_CURATED_PRODUCT_LIST;
    let prodCallString = `${apiConfig}?categoryId=${catID}&fetchSize=${noOfProducts}`;
    console.log('prodCallString: ', prodCallString);
    let cacheKey;
    let cacheResponse;

    if (prodCallString) {
      cacheKey = prodCallString;
      const cacheData = getCache(cacheKey, state);
      cacheResponse = cacheData ? { data: cacheData } : null;
    }
    const curatedProductResponse = (catID, curratedProductList) => {
      dispatch({
        type: `RESOLVED_CMS_CURRATED_PRODUCTS_${catID}`,
        payload: curratedProductList,
        catID,
      });
    };

    if (cacheResponse) {
      return new Promise((resolve) => {
        curatedProductResponse(catID, cacheResponse.data);
        return resolve();
      });
    }
    const internationalToggle = get(state, 'toggles.INTERNATIONAL', false);
    if (internationalToggle) {
      const currencyCode = get(state, 'locale.currencyCode', 'USD');
      if (currencyCode !== 'USD') {
        const currencyQuery = `&currency=${currencyCode}`;
        prodCallString = `${prodCallString}${currencyQuery}`;
      }
    }
    if (prodCallString.indexOf('?') > -1) {
      const mainShotType = `&mainShotType=${MAIN_SHOT_TYPE}`;
      prodCallString = `${prodCallString}${mainShotType}`;
    }
    return requestApi.get(prodCallString, { headers })
      .then((res) => {
        if (!res?.data?.products?.length) {
          dispatch({
            type: `REJECTED_CMS_CURRATED_PRODUCTS_${catID}`,
            catID,
          });
        }

        const curratedProductList = [];
        for (const product of res.data.products) {
          const imageSet = getCloudinaryImageSetIfEnabled(product?.media?.main);
          const imageUrl = imageSet?.medium?.url
            || '/assets/images/no-image.c9a49578722aabed021ab4821bf0e705.jpeg';

          const designerName = get(product, 'designer.name', ' ');
          const name = product.name || '';
          const isGroup = product.isGroup;
          const childProducts = isGroup ? get(product, 'childProducts', []) : [];
          const displayable = product.displayable;

          curratedProductList.push({
            id: product.id,
            designerName,
            name,
            url: get(product, 'details.canonicalUrl', ''),
            price: product?.price,
            imageUrl,
            title: getTitle(product),
            promotions: product.promotions,
            isGroup,
            childProducts,
            displayable,
          });
        }
        if (cacheKey) {
          const mcacheTimeout = get(state, 'mcacheTimeouts.cproducts', 900000);
          putCache(cacheKey, curratedProductList, mcacheTimeout, state);
        }
        curatedProductResponse(catID, curratedProductList);
      })
      .catch((err) => {
        log.error(`There was an error rendering category products for carousel: ${err}`);
        dispatch({
          type: `REJECTED_CMS_CURRATED_PRODUCTS_${catID}`,
          catID,
        });
      });
  };
}

export function editorialNavlinkClick(editorialClass) {
  return (dispatch) => {
    dispatch({
      type: EDITORIAL_NAVLINK_CLICK,
      payload: editorialClass,
    });
  };
}

export function getModalContent(contentId) {
  return async (dispatch, getState) => {
    dispatch({ type: `LOADING_MODAL_CONTENT_${contentId}` });

    try {
      const state = getState();
      const timeout = get(state, 'apiTimeouts.SLS_CONTENT_API_TIMEOUT', 5000);
      const isMobileVersionAvailable = get(
        state,
        'toggles.MOBILE_VERSION',
        false,
      );
      const queryParams = { contentId };
      const requestApi = (url) => httpWithLogging(state, timeout).get(url);
      const updatedContentUrl = getServiceUrl(
        queryParams,
        isMobileVersionAvailable,
        false,
        true,
      );

      const response = await requestApi(updatedContentUrl);
      const getData = isArray(response.data) ? response.data[0] : response.data;
      const modalContent = getData || {};

      if (modalContent.sys.contentType.sys.id !== 'productCarouselContentAsset') {
        dispatch({
          type: `RESOLVED_MODAL_CONTENT_${contentId}`,
          payload: modalContent,
          contentId,
        });
      }

      return modalContent;
    } catch (err) {
      dispatch({ type: `REJECTED_MODAL_CONTENT_${contentId}` });

      return err;
    }
  };
}

export function setActiveEntryId(entryId) {
  return (dispatch) => {
    dispatch({
      type: SET_ACTIVE_ENTRY_ID,
      payload: entryId,
    });
  };
}

export function openProductPanel({
  ids = [],
  ymalIds = [],
  styledForYouCard,
  ppOpener,
  componentId,
  panelDescription,
  carouselName = '',
}) {
  const rtsIds = Array.isArray(ids) ? ids : [ids];
  const productsLength = rtsIds.length;
  const ymalProductsLength = ymalIds.length;
  const idsToFetch = ymalProductsLength > 0 ? [...rtsIds, ...ymalIds] : rtsIds;
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: LOADING_PRODUCT_PANEL,
        payload: {
          products: productsLength,
          productsYMAL: ymalProductsLength,
        },
      });
      const state = getState();
      const { user, session } = state;
      const timeout = 5000;
      const headers = {
        Cookie: buildCookieString({
          JSESSIONID: get(session, 'JSESSIONID', ''),
          ucid: get(user, 'ucid', ''),
          rid: get(user, 'rid', 'US'),
          DYN_USER_ID: get(session, 'DYN_USER_ID', ''),
          W2A: get(session, 'W2A', ''),
        }),
      };
      const apiString = `${NMConfig.API_PRODUCT}?productIds=${idsToFetch.join()}&calledBy=SRP`;
      const requestProducts = httpWithLogging(state, timeout).get(apiString, { headers });
      const [productsResponse, favProductsResponse] = await Promise.all([
        requestProducts,
        getFavoriteList(session),
      ]);

      let { products } = productsResponse.data;
      products = products.sort(productIdComparator(idsToFetch));
      products = markProductsIsFavorites(products, favProductsResponse);
      let productsYMAL = ymalProductsLength > 0
        ? remove(products, ({ id }) => ymalIds.includes(id))
        : [];
      productsYMAL = productsYMAL.length > 0
        ? markProductsAsYMAL(productsYMAL)
        : productsYMAL;

      dispatch({
        type: OPEN_PRODUCT_PANEL,
        payload: {
          products,
          productsYMAL,
          styledForYouCard,
          ppOpener,
          componentId,
          panelDescription,
          carouselName,
        },
      });
      return products;
    } catch (err) {
      dispatch({
        type: ERROR_PRODUCT_PANEL,
        payload: {
          msg: 'Product info not available',
          numberOfProducts: productsLength,
          numberOfProductsYMAL: ymalProductsLength,
        },
      });
      return err;
    }
  };
}

export function closeProductPanel() {
  return (dispatch) => dispatch({ type: CLOSE_PRODUCT_PANEL });
}

export function toggleProductPanelFavorite(product, isFavorite) {
  const { cmosCatalogId, cmosItem, isYMALProduct } = product;
  const favoriteData = {
    favoriteStatus: isFavorite ? 'favorite' : 'unfavorite',
    cmosCatalogId,
    cmosItem,
    isYMALProduct,
  };

  return (dispatch) => dispatch({ type: FAVORITE_PRODUCT_PANEL, payload: favoriteData });
}

export function drawerControl(isOpen) {
  return (dispatch) => dispatch({ type: DRAWER_HANDLER, payload: isOpen });
}

export function isGlobalNavUpdateToggleEnabled(state) {
  const isDomestic = get(state, 'locale.countryCode') === 'US';
  const globalNavUpdateToggle = get(state, 'toggles.GLOBAL_NAV_UPDATE', false);
  return isDomestic && Boolean(globalNavUpdateToggle);
}
