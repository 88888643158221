export const US_COUNTRY_CODE = 'US';
export const PRECIOUS_JEWELRY_PAYPAL_MESSAGE = 'Item may not be purchased with Paypal';
export const PERISHABLE_MESSAGE = 'To ensure freshness, perishables cannot be delivered on Sat, Sun, Mon, or Holidays. Please select a date on which you would like to receive this item by clicking on the calendar icon below.';
export const MONOGRAM_HELPLINE = 'This item requires special assistance, to order call 1.888.888.4757.';
export const REQUIRED_MONOGRAM = 'R';
export const SUPPRESSED_MONOGRAM = 'N';
export const FREE_MONOGRAM = 'F';
export const MONOGRAM_HEADER = 'Personalize Your Item';
export const PDP_PAGINATION_LOCAL_STORAGE_LIMIT = 100;
export const OOS_ERROR_MESSAGE = 'Sorry, your selection is currently out of stock. Please try another color or size.';
export const PDP_PAGINATION_LOOK_AHEAD_THRESHOLD = 3;
export const MONOGRAM_PREVIEW_HELPTEXT = 'Initials may be reordered to reflect traditional monogram style. Capitalization may also be altered by design style.';
export const MONOGRAM_HELP_TEXT = 'Enter your initials in order of first, middle, and last name.';
export const DISCONTINUED_CODES_YR = 'YR';
export const NON_DISCONTINUED_CODE = 'N';
export const DISCONTINUED_CODE_V = 'V';
export const DISCONTINUED_CODE_W = 'W';
export const DISCONTINUED_CODE_Q = 'Q';
export const PROMOTION_TYPE_113 = '113';
export const PAGE_SIZE = 5;
export const APPOINTMENT_RADIUS = '100';
export const CBD_PRODUCTS_SUPPRESSED_FORM_AFFIRM = ['C53AH', 'C52FG', 'C4Y76', 'C53AX', 'C4ZQJ', 'C4ZQR', 'C522P', 'C53AG', 'C518T', 'C4X9V', 'C55X1',
  'C52LH', 'C4ZHA', 'C53AJ', 'C4XA2', 'C53AU', 'C4X9U', 'C53JP', 'C4X9X', 'C539V', 'C4XAJ', 'C51SU', 'C52FD', 'C52FC', 'C4X9Y', 'C51SR', 'C50TR', 'C4X9W',
  'C51V6', 'C521L', 'C4XA1', 'C52FF', 'C53UR', 'C4XA0', 'C54PR', 'C4XAS', 'C52FB', 'C522L', 'C53US', 'C50KE', 'C53AW', 'C539U', 'C50KH', 'C53W3', 'C51SV',
  'C53F0', 'C51SP', 'C50KF', 'C54S4', 'C51ST', 'C51V8', 'C51SS', 'C4X9Z', 'C51V7', 'C522N', 'C522M', 'C53S0', 'C52W9', 'C53RZ', 'C53Q6', 'C53Q2'];
export const BACK_ORDER_MESSAGE = '<span> This item is on <strong>Backorder</strong>.</span>';
export const PRE_ORDER_MESSAGE = '<span> This item is on <strong>Pre-Order</strong>.</span>';
export const SHIP_FORM_VENDOR_STATUS = 'Ships from vendor.';
export const SHIP_FORM_VENDOR_MESSAGE = '<span> This item <strong>Ships from Vendor</strong>.</span>';
export const BACK_ORDER_TEXT = 'We will ship it to you as soon as it is available';
export const SHIPS_FROM_VENDOR = 'Ships from vendor.';
